/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import { RiPrinterLine, RiDownloadLine, RiArrowLeftLine, RiCheckLine, RiMedalLine, RiTrophyLine, RiStarLine } from "react-icons/ri"

const RecognitionProgramFrameworkDownloadPage = () => {
  
  // Function to handle printing the template
  const handlePrint = () => {
    if (typeof window !== 'undefined') {
      window.print();
    }
  }
  
  return (
    <Layout>
      <SEO 
        title="Employee Recognition Program Framework | Engagement Enhancement Tool"
        description="Download our comprehensive recognition program framework to create a culture of appreciation. Includes structured templates and implementation guide."
        keywords={[
          "employee recognition program",
          "staff appreciation framework",
          "workplace recognition strategy",
          "employee rewards system",
          "peer recognition template",
          "employee appreciation guide",
          "recognition program implementation",
          "workplace culture improvement",
          "employee motivation tools",
          "employee engagement framework"
        ]}
      />
      
      {/* Hero Section */}
      <div sx={{
        background: 'linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%)',
        py: [4, 5],
        position: 'relative'
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <div sx={{
            maxWidth: '800px',
            mx: 'auto'
          }}>
            <Link 
              to="/guides/employee-engagement" 
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                color: 'primary',
                fontSize: '0.9rem',
                textDecoration: 'none',
                mb: 3,
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            >
              <RiArrowLeftLine sx={{ mr: 1 }} /> Back to Employee Engagement Guide
            </Link>
            
            <h1 sx={{
              fontSize: ['1.8rem', '2.2rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary'
            }}>
              Recognition Program Framework
            </h1>
            
            <p sx={{
              fontSize: '1rem',
              lineHeight: 1.5,
              mb: 4,
              color: 'text',
              opacity: 0.9
            }}>
              A comprehensive framework for building and implementing an effective employee 
              recognition program that boosts engagement, reinforces core values, and creates 
              a culture of appreciation.
            </p>
            
            <div sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 3,
              mb: 4
            }}>
              <button 
                onClick={handlePrint}
                type="button"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'primary',
                  color: 'white',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '0.95rem',
                  fontWeight: 600,
                  border: 'none',
                  cursor: 'pointer',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.15)'
                  }
                }}
              >
                <RiPrinterLine /> Print Framework
              </button>
              
              <Link 
                to="/contact"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'white',
                  color: 'primary',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '0.95rem',
                  fontWeight: 600,
                  textDecoration: 'none',
                  border: '2px solid',
                  borderColor: 'primary',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  boxShadow: '0 4px 10px rgba(0,0,0,0.05)',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.1)'
                  }
                }}
              >
                <RiDownloadLine /> Request Full Framework
              </Link>
            </div>
          </div>
        </div>
      </div>
      
      {/* Template content will be added in subsequent edits */}
      
      {/* Main content */}
      <div className="printable-content" sx={{
        maxWidth: '1200px',
        mx: 'auto',
        px: 3,
        py: [4, 5],
        '@media print': {
          padding: '0',
          fontSize: '12pt'
        }
      }}>
        <div sx={{
          maxWidth: '800px',
          mx: 'auto',
          bg: 'white',
          p: [3, 4],
          borderRadius: '8px',
          boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
          '@media print': {
            boxShadow: 'none',
            padding: '0'
          }
        }}>
          {/* Introduction Section */}
          <section sx={{ mb: 5 }}>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'muted',
              pb: 2
            }}>
              Introduction
            </h2>
            <p sx={{ mb: 3, lineHeight: 1.6 }}>
              An effective recognition program is a structured approach to acknowledging and rewarding employees 
              for their contributions, achievements, and behaviors that align with organizational values and goals. 
              This framework provides a comprehensive guide to implementing a recognition program that boosts morale, 
              increases engagement, and enhances organizational culture.
            </p>
            <p sx={{ lineHeight: 1.6 }}>
              According to research, companies with effective recognition programs have 31% lower voluntary turnover 
              rates and are 12 times more likely to generate strong business results. This framework is designed to 
              help you create a recognition system that is meaningful, consistent, and impactful.
            </p>
          </section>

          {/* Core Components Section */}
          <section sx={{ mb: 5 }}>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'muted',
              pb: 2
            }}>
              Core Components of an Effective Recognition Program
            </h2>
            
            <div sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
              gap: 4,
              mb: 4
            }}>
              {/* Component 1 */}
              <div sx={{
                p: 3,
                borderRadius: '8px',
                border: '1px solid',
                borderColor: 'muted',
                bg: '#f8f9fa'
              }}>
                <div sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  mb: 2,
                  color: 'primary' 
                }}>
                  <RiStarLine sx={{ fontSize: '1.5rem', mr: 2 }} />
                  <h3 sx={{ fontSize: '1.2rem', fontWeight: 600 }}>
                    Clear Criteria
                  </h3>
                </div>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.6 }}>
                  Define specific behaviors, achievements, and contributions that deserve recognition, 
                  aligned with company values and strategic objectives.
                </p>
              </div>
              
              {/* Component 2 */}
              <div sx={{
                p: 3,
                borderRadius: '8px',
                border: '1px solid',
                borderColor: 'muted',
                bg: '#f8f9fa'
              }}>
                <div sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  mb: 2,
                  color: 'primary' 
                }}>
                  <RiMedalLine sx={{ fontSize: '1.5rem', mr: 2 }} />
                  <h3 sx={{ fontSize: '1.2rem', fontWeight: 600 }}>
                    Multiple Recognition Types
                  </h3>
                </div>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.6 }}>
                  Implement various forms of recognition, from formal awards and monetary incentives 
                  to informal peer-to-peer acknowledgments and public appreciation.
                </p>
              </div>
              
              {/* Component 3 */}
              <div sx={{
                p: 3,
                borderRadius: '8px',
                border: '1px solid',
                borderColor: 'muted',
                bg: '#f8f9fa'
              }}>
                <div sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  mb: 2,
                  color: 'primary' 
                }}>
                  <RiTrophyLine sx={{ fontSize: '1.5rem', mr: 2 }} />
                  <h3 sx={{ fontSize: '1.2rem', fontWeight: 600 }}>
                    Consistency & Timeliness
                  </h3>
                </div>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.6 }}>
                  Ensure recognition is delivered consistently and promptly after the 
                  achievement or behavior to maximize its impact and reinforce desired actions.
                </p>
              </div>
              
              {/* Component 4 */}
              <div sx={{
                p: 3,
                borderRadius: '8px',
                border: '1px solid',
                borderColor: 'muted',
                bg: '#f8f9fa'
              }}>
                <div sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  mb: 2,
                  color: 'primary' 
                }}>
                  <RiCheckLine sx={{ fontSize: '1.5rem', mr: 2 }} />
                  <h3 sx={{ fontSize: '1.2rem', fontWeight: 600 }}>
                    Accessibility & Inclusivity
                  </h3>
                </div>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.6 }}>
                  Design a program that is fair, transparent, and accessible to all employees 
                  regardless of their role, location, or department within the organization.
                </p>
              </div>
            </div>
          </section>

          {/* Implementation Framework */}
          <section sx={{ mb: 5 }}>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'muted',
              pb: 2
            }}>
              Implementation Framework
            </h2>
            
            <div sx={{ mb: 4 }}>
              <h3 sx={{ 
                fontSize: '1.2rem', 
                fontWeight: 600, 
                mb: 2,
                color: 'text' 
              }}>
                1. Program Design
              </h3>
              <ul sx={{ pl: 4, mb: 3 }}>
                <li sx={{ mb: 2 }}>Define program objectives and success metrics</li>
                <li sx={{ mb: 2 }}>Identify the types of recognition to be included:
                  <ul sx={{ pl: 4, mt: 1 }}>
                    <li>Formal awards (Employee of the Month, Annual Excellence Awards)</li>
                    <li>Monetary rewards (bonuses, gift cards, experience vouchers)</li>
                    <li>Peer-to-peer recognition mechanisms</li>
                    <li>Milestone acknowledgments (work anniversaries, project completions)</li>
                    <li>On-the-spot recognition for daily achievements</li>
                  </ul>
                </li>
                <li sx={{ mb: 2 }}>Establish clear criteria for each recognition type</li>
                <li sx={{ mb: 2 }}>Determine budget allocation and resources required</li>
              </ul>
            </div>
            
            <div sx={{ mb: 4 }}>
              <h3 sx={{ 
                fontSize: '1.2rem', 
                fontWeight: 600, 
                mb: 2,
                color: 'text' 
              }}>
                2. Program Infrastructure
              </h3>
              <ul sx={{ pl: 4, mb: 3 }}>
                <li sx={{ mb: 2 }}>Select appropriate recognition tools and platforms</li>
                <li sx={{ mb: 2 }}>Develop nomination and selection processes</li>
                <li sx={{ mb: 2 }}>Create recognition templates and resources</li>
                <li sx={{ mb: 2 }}>Design award certificates and physical recognition items</li>
                <li sx={{ mb: 2 }}>Establish a recognition calendar with regular events</li>
              </ul>
            </div>
            
            <div sx={{ mb: 4 }}>
              <h3 sx={{ 
                fontSize: '1.2rem', 
                fontWeight: 600, 
                mb: 2,
                color: 'text' 
              }}>
                3. Communication Plan
              </h3>
              <ul sx={{ pl: 4, mb: 3 }}>
                <li sx={{ mb: 2 }}>Develop program branding and messaging</li>
                <li sx={{ mb: 2 }}>Create a comprehensive program announcement strategy</li>
                <li sx={{ mb: 2 }}>Design training materials for managers and employees</li>
                <li sx={{ mb: 2 }}>Establish regular communication channels for recognition stories</li>
                <li sx={{ mb: 2 }}>Prepare FAQs and program documentation</li>
              </ul>
            </div>
            
            <div sx={{ mb: 4 }}>
              <h3 sx={{ 
                fontSize: '1.2rem', 
                fontWeight: 600, 
                mb: 2,
                color: 'text' 
              }}>
                4. Launch and Execution
              </h3>
              <ul sx={{ pl: 4, mb: 3 }}>
                <li sx={{ mb: 2 }}>Conduct kickoff events and training sessions</li>
                <li sx={{ mb: 2 }}>Initiate pilot program if necessary</li>
                <li sx={{ mb: 2 }}>Roll out comprehensive program organization-wide</li>
                <li sx={{ mb: 2 }}>Ensure leadership participation and modeling</li>
                <li sx={{ mb: 2 }}>Monitor early adoption and provide support</li>
              </ul>
            </div>
            
            <div>
              <h3 sx={{ 
                fontSize: '1.2rem', 
                fontWeight: 600, 
                mb: 2,
                color: 'text' 
              }}>
                5. Evaluation and Optimization
              </h3>
              <ul sx={{ pl: 4, mb: 3 }}>
                <li sx={{ mb: 2 }}>Collect program metrics and employee feedback</li>
                <li sx={{ mb: 2 }}>Evaluate program performance against objectives</li>
                <li sx={{ mb: 2 }}>Identify improvement opportunities</li>
                <li sx={{ mb: 2 }}>Make necessary adjustments to criteria, processes, or rewards</li>
                <li sx={{ mb: 2 }}>Regularly refresh program elements to maintain engagement</li>
              </ul>
            </div>
          </section>
          
          {/* Recognition Types Detailed */}
          <section sx={{ mb: 5 }}>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'muted',
              pb: 2
            }}>
              Recognition Types & Examples
            </h2>
            
            <table sx={{
              width: '100%',
              borderCollapse: 'collapse',
              mb: 4,
              fontSize: '0.95rem'
            }}>
              <thead>
                <tr sx={{
                  bg: '#f8f9fa',
                  borderBottom: '2px solid',
                  borderColor: 'muted'
                }}>
                  <th sx={{ 
                    p: 2, 
                    textAlign: 'left',
                    fontWeight: 600
                  }}>Recognition Type</th>
                  <th sx={{ 
                    p: 2, 
                    textAlign: 'left',
                    fontWeight: 600
                  }}>Examples</th>
                  <th sx={{ 
                    p: 2, 
                    textAlign: 'left',
                    fontWeight: 600
                  }}>Best Practices</th>
                </tr>
              </thead>
              <tbody>
                <tr sx={{ borderBottom: '1px solid', borderColor: 'muted' }}>
                  <td sx={{ p: 2, fontWeight: 600 }}>Day-to-Day Recognition</td>
                  <td sx={{ p: 2 }}>
                    <ul sx={{ pl: 3, m: 0 }}>
                      <li>Verbal thank you</li>
                      <li>Appreciation emails</li>
                      <li>Recognition in team meetings</li>
                      <li>Digital badges or points</li>
                    </ul>
                  </td>
                  <td sx={{ p: 2 }}>
                    <ul sx={{ pl: 3, m: 0 }}>
                      <li>Be specific about the behavior</li>
                      <li>Deliver immediately</li>
                      <li>Make it personal</li>
                    </ul>
                  </td>
                </tr>
                <tr sx={{ borderBottom: '1px solid', borderColor: 'muted' }}>
                  <td sx={{ p: 2, fontWeight: 600 }}>Formal Awards</td>
                  <td sx={{ p: 2 }}>
                    <ul sx={{ pl: 3, m: 0 }}>
                      <li>Employee of the Month</li>
                      <li>Quarterly Value Champions</li>
                      <li>Annual Excellence Awards</li>
                      <li>Innovation Awards</li>
                    </ul>
                  </td>
                  <td sx={{ p: 2 }}>
                    <ul sx={{ pl: 3, m: 0 }}>
                      <li>Establish transparent criteria</li>
                      <li>Include peer nominations</li>
                      <li>Celebrate publicly</li>
                    </ul>
                  </td>
                </tr>
                <tr sx={{ borderBottom: '1px solid', borderColor: 'muted' }}>
                  <td sx={{ p: 2, fontWeight: 600 }}>Milestone Recognition</td>
                  <td sx={{ p: 2 }}>
                    <ul sx={{ pl: 3, m: 0 }}>
                      <li>Work anniversaries</li>
                      <li>Project completions</li>
                      <li>Goal achievements</li>
                      <li>Career advancement</li>
                    </ul>
                  </td>
                  <td sx={{ p: 2 }}>
                    <ul sx={{ pl: 3, m: 0 }}>
                      <li>Personalize to the individual</li>
                      <li>Include career journey highlights</li>
                      <li>Incorporate team celebration</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td sx={{ p: 2, fontWeight: 600 }}>Peer-to-Peer Recognition</td>
                  <td sx={{ p: 2 }}>
                    <ul sx={{ pl: 3, m: 0 }}>
                      <li>Digital recognition platforms</li>
                      <li>Shout-out boards</li>
                      <li>Peer nomination programs</li>
                      <li>Appreciation cards</li>
                    </ul>
                  </td>
                  <td sx={{ p: 2 }}>
                    <ul sx={{ pl: 3, m: 0 }}>
                      <li>Make it easy to participate</li>
                      <li>Provide simple templates</li>
                      <li>Encourage regular participation</li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
          
          {/* Measuring Success */}
          <section>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'muted',
              pb: 2
            }}>
              Measuring Program Success
            </h2>
            
            <p sx={{ mb: 3, lineHeight: 1.6 }}>
              To ensure your recognition program is effective and impactful, track these key metrics:
            </p>
            
            <ul sx={{ pl: 4, mb: 4 }}>
              <li sx={{ mb: 2 }}>
                <strong>Participation rate:</strong> Percentage of employees giving and receiving recognition
              </li>
              <li sx={{ mb: 2 }}>
                <strong>Recognition frequency:</strong> Number of recognition instances per employee per month
              </li>
              <li sx={{ mb: 2 }}>
                <strong>Employee engagement scores:</strong> Measured through regular surveys
              </li>
              <li sx={{ mb: 2 }}>
                <strong>Retention impact:</strong> Turnover rates among recognized vs. non-recognized employees
              </li>
              <li sx={{ mb: 2 }}>
                <strong>Program satisfaction:</strong> Feedback on the effectiveness and value of the program
              </li>
              <li sx={{ mb: 2 }}>
                <strong>Behavior alignment:</strong> Increase in behaviors aligned with organizational values
              </li>
              <li sx={{ mb: 2 }}>
                <strong>ROI measures:</strong> Program costs vs. benefits (reduced turnover, increased productivity)
              </li>
            </ul>
            
            <p sx={{ 
              bg: '#f8f9fa', 
              p: 3, 
              borderRadius: '6px',
              border: '1px solid',
              borderColor: 'muted',
              fontStyle: 'italic'
            }}>
              Remember: The most effective recognition programs evolve over time based on feedback and changing 
              organizational needs. Regularly review and refresh your program to maintain its impact and relevance.
            </p>
          </section>
          
          {/* Print styles */}
          <style jsx>{`
            @media print {
              body {
                font-size: 12pt;
                color: #000;
              }
              h1, h2, h3, h4, h5, h6 {
                break-after: avoid;
              }
              ul, ol, table {
                break-inside: avoid;
              }
              a {
                text-decoration: none;
                color: #000;
              }
              .printable-content {
                padding: 0;
              }
            }
          `}</style>
        </div>
      </div>
      
    </Layout>
  )
}

export default RecognitionProgramFrameworkDownloadPage 